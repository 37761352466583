@defer (when render()) {
  @if (authGuard()) {
    @if (centerGuard()) {
      <router-outlet />
    } @else {
      <div
        class="flex flex-col gap-8 w-full min-h-[50rem] h-full items-center justify-center"
      >
        <app-icon
          name="material-symbols:person-cancel-rounded"
          class="!size-14 text-gray-300"
        />
        <div class="flex flex-col items-center gap-3">
          <p class="text-2xl font-semibold text-gray-800">
            소속된 센터가 없어요.
          </p>
          <p class="text-sm text-gray-500">
            센터를 찾거나 직접 센터를 등록해주세요.
          </p>
        </div>
        <div class="flex items-center gap-3">
          <app-button
            routerLink="/center/form"
            iconClass="text-primary"
            icon="mynaui:heart-home-solid"
            variant="outline"
            color="gray"
            >센터 생성</app-button
          >
          <app-button routerLink="/center/find" icon="ic:round-search"
            >센터 찾기</app-button
          >
        </div>
        <app-button
          (click)="openGuideModal()"
          variant="outline"
          icon="material-symbols:book-2-rounded"
          size="sm"
          >센터 찾기 가이드</app-button
        >
      </div>
    }
  } @else {
    <div
      class="flex flex-col gap-8 w-full min-h-[50rem] h-full items-center justify-center"
    >
      <app-icon
        name="material-symbols:person-cancel-rounded"
        class="!size-14 text-gray-300"
      />
      <div class="flex flex-col items-center gap-3">
        <p class="text-2xl font-semibold text-gray-800">로그인이 필요해요.</p>
        <p class="text-sm text-gray-500">로그인 후 이용해주세요.</p>
      </div>
      <app-button size="lg" (click)="login()" icon="ic:round-login"
        >로그인 하러가기</app-button
      >
    </div>
  }
}
