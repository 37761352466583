import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  Badge,
  Icon,
  IconButton,
  Menu,
  MenuOption,
} from '@malirang/front-share';

@Component({
  selector: 'app-join-card',
  templateUrl: './join-card.component.html',
  styleUrls: ['./join-card.component.scss'],
  imports: [
    Icon,
    Badge,
    CommonModule,
    Menu,
    MenuOption,
    CdkMenuModule,
    IconButton,
  ],
})
export class JoinCardComponent {}
