<div class="card-wrapper">
  <div class="flex w-full justify-between items-center">
    <div class="flex gap-2 items-center">
      <app-badge size="sm" variant="outline">승인</app-badge>
      <p class="text-gray-800 font-semibold">{{ '고식혜' }}</p>
    </div>
    <app-icon-button
      [cdkMenuTriggerFor]="menu"
      [cdkMenuPosition]="[
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]"
      icon="mingcute:more-2-line"
      class="text-gray-500"
    />
  </div>
  <div class="flex gap-3 items-center">
    <div class="item">
      <app-icon size="sm" name="material-symbols:attach-email" />
      <p>{{ 'gg@gg.com' }}</p>
    </div>
    <div class="bg-gray-200 h-3 w-px"></div>
    <div class="item">
      <app-icon size="sm" name="material-symbols:account-box" />
      <p>{{ '선생님' }}</p>
    </div>
    <div class="bg-gray-200 h-3 w-px"></div>
    <div class="item">
      <app-icon size="sm" name="material-symbols:calendar-month" />
      <p>초대일시</p>
      <p>{{ '2024-11-11 13:00 / 홍길동' }}</p>
    </div>
    <div class="bg-gray-200 h-3 w-px"></div>
    <div class="item">
      <app-icon size="sm" name="material-symbols:calendar-month" />
      <p>마지막 업데이트</p>
      <p>{{ '2024-11-11 13:00 / 고식혜' }}</p>
    </div>
  </div>
</div>

<ng-template #menu>
  <app-menu cdkMenu>
    <app-menu-option cdkMenuItem>승인</app-menu-option>
    <app-menu-option cdkMenuItem>반려</app-menu-option>
  </app-menu>
</ng-template>
