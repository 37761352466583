/* eslint-disable @nx/enforce-module-boundaries */
import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Icon, ModalService, SLIDE_Y } from '@malirang/front-share';
import { environment } from 'apps/client/src/env/env';
import { CenterRoleBadgeComponent } from '../../../components/center/role-badge/center-role-badge.component';
import { CenterStore } from '../../../stores/center.store';
import { UserStore } from '../../../stores/user.store';
import { MenuItem } from '../layout.page';
import { SelectCenterComponent } from '../select-center/select-center.component';
import { SideMenuComponent } from '../side-menu/side-menu.component';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    CdkMenuModule,
    RouterModule,
    Icon,
    SelectCenterComponent,
    CenterRoleBadgeComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  animations: [SLIDE_Y],
})
export class HeaderComponent {
  readonly userStore = inject(UserStore);
  readonly centerStore = inject(CenterStore);
  readonly modalService = inject(ModalService);

  user = this.userStore.user;
  center = this.centerStore.currentCenter;

  menus = input.required<MenuItem[]>();

  menuItems = [
    {
      label: '마이 페이지',
      icon: 'solar:user-circle-bold-duotone',
      url: '/my-page',
    },
    {
      label: '고객센터',
      icon: 'solar:call-chat-rounded-bold-duotone',
      url: '/customer',
    },
  ];

  openSidemenu() {
    this.modalService.create(SideMenuComponent);
  }

  logout() {
    this.userStore.logout();
  }

  login() {
    window.location.href = `${environment.landing.url}/login`;
  }
}
