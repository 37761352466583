@if (layout() === 'default') {
  <div
    class="relative flex flex-col w-full min-h-screen overflow-x-hidden overflow-y-auto"
  >
    <div class="xl:pt-[124px] pt-[71px]"></div>
    <app-header class="z-[999] fixed w-full" [menus]="menus" />

    <!-- router outlet 경로 -->
    <app-center-guard class="min-h-[65vh]" />

    <div class="xl:pb-0 pb-[60px]"></div>
    <app-footer class="hidden xl:block" />
    <app-bottom-sheet class="block xl:hidden" [menus]="menus" />
  </div>
} @else {
  <div class="relative w-full min-h-screen overflow-x-hidden overflow-y-auto">
    <router-outlet />
  </div>
}
