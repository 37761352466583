import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { CenterDto } from '@malirang/api-client';
import { Icon } from '@malirang/front-share';
import { CenterIconComponent } from '../icon/center-icon.component';
import { CenterRoleBadgeComponent } from '../role-badge/center-role-badge.component';

@Component({
  selector: 'app-center-profile',
  standalone: true,
  imports: [CommonModule, Icon, CenterIconComponent, CenterRoleBadgeComponent],
  templateUrl: './center-profile.component.html',
  styleUrl: './center-profile.component.scss',
})
export class CenterProfileComponent {
  center = input.required<CenterDto>();
  active = input<boolean>(false);
}
