@if (isMobile()) {
  <app-my-page-modal-container
    [segmentOptions]="segmentOptions"
    backUrl="/schedule"
  >
    <ng-container *ngTemplateOutlet="content" />
  </app-my-page-modal-container>
} @else {
  <app-container>
    <ng-container *ngTemplateOutlet="content" />
  </app-container>
}

<ng-template #content>
  <section
    class="lg:py-[60px] lg:px-0 pt-8 pb-4 px-4 flex md:flex-row flex-col md:gap-6 gap-4 w-full"
  >
    <div class="self-start hidden w-full flex-[1.5] md:block">
      <ng-container *ngTemplateOutlet="side" />
    </div>
    <div class="w-full">
      <router-outlet />
    </div>
  </section>
</ng-template>

<ng-template #side>
  <div
    class="flex flex-col items-start self-start flex-1 w-full gap-3 xl:w-[18.125rem]"
  >
    <div
      class="p-5 w-full border border-gray-100 rounded-[20px] flex items-center flex-col gap-3"
    >
      <app-center-icon size="lg" />
      @if (user(); as user) {
        <p class="text-xl font-semibold text-gray-800">
          {{ user?.name }}
        </p>
        @if (user.auths && user.auths.length > 0) {
          <p class="text-gray-500">
            {{ user.auths[0]!.email }}
          </p>
        }
      }
    </div>

    @for (option of segmentOptions; track $index) {
      <div
        class="w-full p-5 font-semibold text-gray-800 transition-all duration-300 ease-in-out cursor-pointer rounded-xl hover:bg-gray-50"
        [routerLinkActive]="['bg-primary-50', 'hover:bg-primary-50']"
        [routerLink]="'/my-page/' + option.value"
      >
        {{ option.label }}
      </div>
    }
  </div>
</ng-template>
