<section class="flex flex-col gap-4 xl:gap-6">
  <div class="flex items-center justify-between w-full">
    <h3 class="hidden xl:block xl:text-xl">센터 목록</h3>
    <div class="flex items-center w-full gap-2 xl:w-auto">
      <app-button
        expand
        class="min-w-max"
        variant="outline"
        routerLink="/center/find"
        icon="material-symbols-light:edit-document"
        >센터 가입</app-button
      >
      <app-button
        expand
        class="min-w-max"
        routerLink="/center/form"
        icon="mynaui:heart-home-solid"
        >센터 생성</app-button
      >
    </div>
  </div>
  <app-segment
    [(value)]="segment"
    (valueChange)="segmentChange()"
    [options]="segments"
  />
  <div>
    <router-outlet />
  </div>
</section>
