import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CapacitorKakaoLogin } from '@team-lepisode/capacitor-kakao-login';
import { initializeApp } from 'firebase/app';
import { environment } from '../env/env';
import { register } from 'swiper/element/bundle';

register();

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'client';

  ngOnInit(): void {
    CapacitorKakaoLogin.initialize({ appKey: environment.kakao.appKey });
    initializeApp(environment.firebase);
  }
}
