import { CommonModule } from '@angular/common';
import { Component, computed, model } from '@angular/core';
import { CenterUserStatus } from '@malirang/common';
import {
  Button,
  Checkbox,
  Input,
  Menu,
  Segment,
  SegmentOption,
  SelectPage,
} from '@malirang/front-share';
import { ClickOutside } from 'ngxtension/click-outside';
import { JoinCardComponent } from './components/join-card/join-card.component';
import { RouterOutlet } from '@angular/router';

/**
 * 초대/가입신청 내역
 */
@Component({
  selector: 'app-center-join-layout',
  templateUrl: './center-join.layout.html',
  imports: [
    CommonModule,
    Segment,
    Button,
    Input,
    SelectPage,
    JoinCardComponent,
    Menu,
    Checkbox,
    ClickOutside,
    RouterOutlet,
  ],
})
export default class CenterJoinLayout {
  segments = computed<SegmentOption[]>(() => [
    { label: '초대', value: 'invite', count: 4 },
    { label: '가입 신청', value: 'request', count: 2 },
  ]);

  statusOptions = Object.entries(CenterUserStatus).map(([key, value]) => ({
    value: key,
    label: value,
  }));

  segment = model('invite');
  query = model('');
  pageNo = model(1);
  openFilter = model(false);

  selectedStatus = model<string[]>([]);

  checkStatus(ev: boolean, status: any) {
    if (ev) {
      this.selectedStatus.set([...this.selectedStatus(), status]);
    } else {
      this.selectedStatus.set(
        this.selectedStatus().filter((s) => s !== status),
      );
    }
  }
}
