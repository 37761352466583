import { Component, inject, model, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Segment, SegmentOption } from '@malirang/front-share';
import { injectNavigationEnd } from 'ngxtension/navigation-end';

/**
 * 센터 템플릿
 */
@Component({
  selector: 'app-center-template-layout',
  templateUrl: './center-template.layout.html',
  imports: [Segment, RouterOutlet],
})
export class CenterTemplateLayout implements OnInit {
  readonly router = inject(Router);

  navigationEnd$ = injectNavigationEnd();

  segment = model<string>('ticket');
  segmentOptions: SegmentOption[] = [
    { label: '발달서비스 관리', value: 'ticket' },
    { label: '바우처 관리', value: 'voucher' },
    { label: '아동태그 관리', value: 'tag' },
    { label: '치료유형 관리', value: 'ticket-type' },
  ];

  ngOnInit(): void {
    this.setSegment();

    this.navigationEnd$.subscribe(() => {
      this.setSegment();
    });
  }

  setSegment() {
    this.segment.set(this.router.url.split('/').pop()!);
  }

  handleSegmentChange() {
    this.router.navigate(['/center/template', this.segment()]);
  }
}
