<section class="flex flex-col gap-6">
  <app-segment [(value)]="segment" [options]="segments()" />
  <div class="flex w-full justify-between items-center">
    <div class="flex gap-3 items-center">
      <p class="count">전체 <span>16</span></p>
    </div>
    <div class="flex gap-2 items-center">
      <app-input
        class="xl:w-[20rem]"
        [debounce]="300"
        icon="mdi:search"
        placeholder="검색어를 입력해주세요."
        [(value)]="query"
      />
      <ng-container *ngTemplateOutlet="filter" />
      <app-button icon="mdi:plus" appTooltip="사용자를 센터로 초대합니다"
        >초대하기</app-button
      >
    </div>
  </div>
  <div class="flex flex-col gap-3">
    <app-join-card />
  </div>
  <div class="flex w-full justify-center">
    <!-- <app-select-page [(page)]="pageNo" /> -->
  </div>
</section>

<ng-template #filter>
  <div class="relative">
    <app-button
      (click)="openFilter.set(true)"
      variant="outline"
      icon="material-symbols:filter-list"
      >필터</app-button
    >
    @if (openFilter()) {
      <app-menu
        (clickOutside)="openFilter.set(false)"
        class="absolute top-12 right-0 w-[17.5rem]"
      >
        <div class="flex flex-col gap-1">
          @for (option of statusOptions; track $index) {
            <div class="px-2">
              <app-checkbox
                [label]="option.label"
                [value]="selectedStatus().includes(option.value)"
                (valueChange)="checkStatus($event, option.value)"
              />
            </div>
          }
        </div>
      </app-menu>
    }
  </div>
</ng-template>
