import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CenterDto } from '@malirang/api-client';
import { Button } from '@malirang/front-share';
import { CenterProfileComponent } from 'apps/client/src/app/components/center/profile/center-profile.component';

@Component({
  selector: 'app-select-center-content',
  templateUrl: './select-center-content.component.html',
  imports: [CommonModule, Button, CenterProfileComponent, RouterLink],
})
export class SelectCenterContentComponent {
  centers = input.required<CenterDto[]>();
  currentCenter = input<CenterDto | null>(null);
  select = output<CenterDto>();
}
