@if (child(); as child) {
  <app-center-child-guard [centerChild]="centerChild()!">
    <app-container>
      <section
        class="container mx-auto flex flex-col w-full h-full gap-2 xs:pt-10 xl:pt-[40px] xl:pb-6 xl:mx-0"
      >
        <div class="items-center hidden gap-3 xl:flex">
          <div class="flex items-center gap-2">
            <app-icon name="solar:home-2-bold-duotone" class="text-gray-400" />
            <p class="text-gray-600">{{ center()?.name }}</p>
          </div>
          <button
            (click)="navigateChildDetail()"
            class="flex items-center gap-2"
          >
            <app-icon
              name="material-symbols:expand-circle-right-rounded"
              class="text-gray-300"
            />
            <p class="text-gray-600">{{ child.name }} 아동</p>
          </button>
          @if (currentTitle(); as title) {
            <div class="flex items-center gap-2">
              <app-icon
                name="material-symbols:expand-circle-right-rounded"
                class="text-gray-300"
              />
              <p class="text-gray-600">{{ title }}</p>
            </div>
          }
        </div>
        <router-outlet />
      </section>
    </app-container>
  </app-center-child-guard>
}
