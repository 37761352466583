import { CommonModule } from '@angular/common';
import { Component, inject, model, OnInit } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { Button, Segment, SegmentOption } from '@malirang/front-share';

@Component({
  selector: 'app-my-page-center-layout',
  templateUrl: './my-page-center.layout.html',
  imports: [Button, RouterLink, CommonModule, RouterOutlet, Segment],
})
export default class MyPageCenterLayout implements OnInit {
  readonly router = inject(Router);

  segment = model('list');
  segments: SegmentOption[] = [
    { label: '소속 센터', value: 'list' },
    { label: '가입/초대 내역', value: 'join' },
  ];

  ngOnInit() {
    const segment = this.router.url.split('/').pop();
    if (segment) {
      this.segment.set(segment!);
    }
  }

  segmentChange() {
    this.router.navigate([`/my-page/center`, this.segment()]);
  }
}
