import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { Component, computed, inject, model, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import {
  AlertService,
  Icon,
  Segment,
  SegmentOption,
  ToastService,
} from '@malirang/front-share';
import { injectNavigationEnd } from 'ngxtension/navigation-end';
import { CenterIconComponent } from '../../components/center/icon/center-icon.component';
import { CenterRoleBadgeComponent } from '../../components/center/role-badge/center-role-badge.component';
import { ContainerComponent } from '../../components/container/container.component';
import { UserStore } from '../../stores/user.store';
import { CenterPageAdapter } from './adapters/center-page.adapter';

@Component({
  selector: 'app-center-layout',
  templateUrl: './center.layout.html',
  imports: [
    RouterOutlet,
    ContainerComponent,
    CommonModule,
    CenterRoleBadgeComponent,
    CenterIconComponent,
    RouterLink,
    RouterLinkActive,
    Icon,
    Segment,
    CdkMenuModule,
  ],
})
export class CenterLayout extends CenterPageAdapter implements OnInit {
  readonly userStore = inject(UserStore);
  readonly alertService = inject(AlertService);
  readonly toastService = inject(ToastService);
  readonly navigationEnd$ = injectNavigationEnd();

  user = this.userStore.user;

  segment = model('info');
  options: SegmentOption[] = [
    { label: '센터 정보 / 구성원', value: 'info' },
    { label: '센터 공지사항', value: 'notice' },
    { label: '센터 템플릿', value: 'template' },
    { label: '구성원 초대 / 가입', value: 'join' },
    { label: '구성원 역할 관리', value: 'role' },
    { label: '결제 관리', value: 'payment' },
    { label: '센터 알림', value: 'notification' },
  ];

  colors: string[] = [
    '#D75E63',
    '#F43F5E',
    '#FB923C',
    '#FCD34D',
    '#84CC16',
    '#16A34A',
    '#1693C9',
    '#2563EB',
    '#7C3AED',
    '#C026D3',
  ];

  ngOnInit(): void {
    this.setSegment();

    this.navigationEnd$.subscribe(() => {
      this.setSegment();
    });
  }

  setSegment(): void {
    const found = this.options.find((o) => this.router.url.includes(o.value));
    this.segment.set(found ? found.value : 'center');
  }

  onSegmentChange(value: string): void {
    this.router.navigate(['/center', value]);
  }

  /**
   * 대표 색상을 변경합니다.
   * @param color
   */
  setColor(color: string) {
    this.centerService
      .userCenterControllerSetColor({
        id: this.centerId(),
        color,
      })
      .subscribe({
        next: () => {
          this.centerService.userCenterControllerGetMyCenterList().subscribe({
            next: (centers) => {
              this.store.setCenters(centers);
              const found = centers.find((c) => c.id === this.centerId());
              if (found) {
                this.store.setCurrentCenter(found);
              }
            },
          });
        },
      });
  }

  /**
   * 센터를 탈퇴합니다.
   */
  handleExitCenter() {
    this.alertService
      .open({
        title: '센터 탈퇴',
        content: `탈퇴 시 해당 센터에서의 모든 활동이 중지됩니다.
      센터에서의 활동 데이터는 삭제되지 않으며
      다시 활동하려면 재가입이 필요합니다.`,
        type: 'danger',
        buttons: {
          confirm: {
            text: '탈퇴',
            color: 'red',
          },
        },
        data: [
          { key: '센터명', value: this.center()?.name! },
          { key: '이름', value: this.user()?.name! },
          { key: '권한', value: this.store.myRole()?.name! },
        ],
        checkbox: {
          text: `탈퇴 시 내용을 확인했으며, 탈퇴하겠습니다.`,
        },
      })
      .closed.subscribe({
        next: (result) => {
          if (result?.action === 'confirm') {
            this.centerUserService
              .centerUserControllerExit({
                centerId: this.centerId(),
                userId: this.user()?.id || '',
              })
              .subscribe({
                next: () => {
                  window.location.reload();
                },
                error: (err) => {
                  this.toastService.error(err.message);
                },
              });
          }
        },
      });
  }
}
