import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, RouterOutlet } from '@angular/router';
import { ChildService } from '@malirang/api-client';
import { Icon } from '@malirang/front-share';
import { CenterChildGuardComponent } from 'apps/client/src/app/pages/layout/guard/child-guard.component';
import { ContainerComponent } from 'apps/client/src/app/components/container/container.component';
import { CenterStore } from 'apps/client/src/app/stores/center.store';
import { injectParams } from 'ngxtension/inject-params';
import { injectNavigationEnd } from 'ngxtension/navigation-end';
import { toObservableSignal } from 'ngxtension/to-observable-signal';
import { DetailChildStore } from '../detail-child.store';

@Component({
  selector: 'app-detail-child',
  templateUrl: './detail-child.layout.html',
  imports: [
    CommonModule,
    RouterOutlet,
    CenterChildGuardComponent,
    Icon,
    ContainerComponent,
  ],
})
export class DetailChildLayout implements OnInit, OnDestroy {
  readonly childService = inject(ChildService);
  readonly store = inject(DetailChildStore);
  readonly centerStore = inject(CenterStore);
  readonly title = inject(Title);
  readonly router = inject(Router);

  id = injectParams('id'); // 센터 아동 ID
  childId = injectParams('childId'); // 아동 ID
  navigationEnd$ = injectNavigationEnd();

  child = this.store.child;
  centerChild = toObservableSignal(this.store.centerChild);

  center = toObservableSignal(this.centerStore.currentCenter);
  currentTitle = signal<string>('');

  ngOnInit(): void {
    // 아동 정보 조회
    this.store.fetchCenterChild(this.id()!);

    // 아동 정보 조회 완료 후 메모 조회
    this.centerChild.subscribe({
      next: (centerChild) => {
        this.store.fetchMemos(
          centerChild?.centerId || '',
          centerChild?.child.id || '',
        );
      },
    });

    this.currentTitle.set(this.title.getTitle());
    this.navigationEnd$.subscribe({
      next: () => {
        this.currentTitle.set(this.title.getTitle());
      },
    });
  }

  navigateChildDetail() {
    this.router.navigate(['child', this.id(), 'detail', this.childId()]);
  }

  ngOnDestroy(): void {
    this.store.clearChild();
  }
}
