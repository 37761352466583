import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { injectNavigationEnd } from 'ngxtension/navigation-end';
import { CenterGuardComponent } from './guard/center-guard.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { BottomSheetComponent } from '@malirang/app-share';

export type MenuItem = {
  url: string;
  title: string;
  icon: string;
  mobileHidden?: boolean;
};

type Layout = 'default' | 'fullscreen';
@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FooterComponent,
    HeaderComponent,
    BottomSheetComponent,
    CenterGuardComponent, // Router Outlet 경로
  ],
  templateUrl: './layout.page.html',
  styleUrl: './layout.page.scss',
})
export default class LayoutPage implements OnInit {
  readonly route = inject(ActivatedRoute);

  navigationEnd$ = injectNavigationEnd();
  layout = signal<Layout>('default');

  menus: MenuItem[] = [
    {
      title: '업무 캘린더',
      icon: 'solar:calendar-search-bold-duotone',
      url: 'schedule',
    },
    {
      title: '아동업무',
      icon: 'material-symbols-light:child-care',
      url: 'child',
    },
    {
      title: '검사도구',
      icon: 'solar:ruler-pen-bold-duotone',
      url: '1',
    },
    {
      title: '치료 업무',
      icon: 'solar:book-bookmark-bold-duotone',
      url: '1',
    },
    {
      title: '콘텐츠',
      icon: 'solar:chat-round-like-bold-duotone',
      url: 'content',
    },
    {
      title: '센터관리',
      icon: 'solar:home-2-bold-duotone',
      url: 'center',
      mobileHidden: true,
    },
  ];

  ngOnInit(): void {
    this.layout.set(this.route.snapshot.data['layout'] || 'default');

    this.navigationEnd$.subscribe({
      next: () => {
        if (window) {
          window.scrollTo(0, 0);
        }
        this.layout.set(this.route.snapshot.data['layout'] || 'default');
      },
    });
  }
}
