import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import {
  Icon,
  ModalAdapter,
  ModalService,
  SLIDE_X,
} from '@malirang/front-share';
import { Router, RouterLink, RouterModule } from '@angular/router';
import { CenterRoleBadgeComponent } from '../../../components/center/role-badge/center-role-badge.component';
import { UserStore } from '../../../stores/user.store';
import { CenterStore } from '../../../stores/center.store';

interface Item {
  title: string;
  icon: string;
  link: string;
}

interface schedule {
  hour: string;
  type: string;
  title: string;
}

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [
    CommonModule,
    Icon,
    RouterModule,
    CenterRoleBadgeComponent,
    RouterLink,
  ],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss',
  animations: [SLIDE_X],
})
export class SideMenuComponent extends ModalAdapter {
  readonly router = inject(Router);
  readonly user = inject(UserStore).user;
  readonly center = inject(CenterStore).currentCenter;

  menus = signal<Item[]>([
    {
      title: '마이페이지',
      icon: 'solar:user-circle-bold-duotone',
      link: '/my-page',
    },
    {
      title: '고객지원',
      icon: 'solar:call-chat-rounded-bold-duotone',
      link: '/customer',
    },
    {
      title: '설정',
      icon: 'solar:settings-bold-duotone',
      link: 'aa',
    },
  ]);

  schedule = signal<schedule[]>([
    {
      hour: '오전 11:00 ~ 오후 12:00',
      type: '상담',
      title: '김민수 정기치료',
    },
    {
      hour: '오후 11:00 ~ 오후 12:00',
      type: '치료',
      title: '고예림 정기치료',
    },
    {
      hour: '오전 11:00 ~ 오후 3:00',
      type: '센터',
      title: '최혜원 정기치료',
    },
    {
      hour: '오전 11:00 ~ 오후 12:00',
      type: '치료',
      title: '한수진 정기치료',
    },
  ]);
}
