<main
  @slideX
  class="absolute z-[9999] top-0 right-0 min-w-[300px] sm:min-w-[480px] h-screen bg-white flex flex-col overflow-y-scroll"
>
  @if (user(); as user) {
    <section class="flex flex-col gap-4 min-h-[800px] h-full">
      <div class="flex justify-end w-full px-4 py-3">
        <app-icon
          (click)="close()"
          class="text-gray-500 cursor-pointer !size-7"
          name="material-symbols:close-rounded"
        />
      </div>

      <div class="flex flex-col gap-4 px-6">
        <div class="flex items-center justify-between">
          <div class="flex flex-col gap-2">
            <div class="flex items-center gap-1">
              <p class="font-semibold text-gray-800">{{ user.name }} 선생님</p>
              <app-center-role-badge />
            </div>
            <p class="text-xs text-gray-400">{{ user.auths[0].email }}</p>
          </div>
          <app-icon
            name="material-symbols:arrow-forward-ios"
            class="text-gray-300 cursor-pointer"
            size="sm"
          />
        </div>
        <div class="border border-gray-200 rounded-t-xl rounded-b-[20px]">
          <div
            class="bg-[#D75E63] flex items-center justify-between rounded-t-xl px-4 py-2"
          >
            <p class="text-sm text-white">센터</p>
            <img
              src="/my-page/profile-icon.svg"
              class="w-8 h-5"
              alt="프로필 로고"
            />
          </div>
          <div class="flex w-full justify-between p-4">
            <div class="text-gray-800">{{ center()?.name }}</div>
            <button
              (click)="close()"
              routerLink="/center"
              class="flex gap-1 items-center"
            >
              <p class="text-gray-400 text-xs">센터관리</p>
              <app-icon
                class="text-gray-300"
                name="material-symbols:arrow-forward-ios"
                size="xs"
              />
            </button>
          </div>
        </div>

        <div class="flex items-center gap-2.5">
          @for (menu of menus(); track $index) {
            <button
              (click)="close()"
              [routerLink]="menu.link"
              class="flex flex-col items-center flex-1 h-full gap-1 cursor-pointer min-h-14 justify-center"
            >
              <app-icon
                [name]="menu.icon"
                class="text-gray-500"
                routerLinkActive="text-primary"
              />
              <p
                class="text-xs font-semibold text-center text-gray-500"
                routerLinkActive="text-primary"
              >
                {{ menu.title }}
              </p>
            </button>
          }
        </div>

        <div class="border-b border-gray-100"></div>

        <!-- 일정 -->
        <div class="flex flex-col gap-4 p-4 border border-gray-100 rounded-2xl">
          <div class="flex items-center justify-between">
            <p class="font-semibold text-gray-800">오늘 일정</p>
            <button>
              <app-icon
                name="material-symbols:arrow-forward-ios"
                class="text-gray-200"
                size="sm"
              />
            </button>
          </div>
          <div class="flex flex-col gap-3.5">
            @for (item of schedule(); track $index) {
              <div class="flex flex-col gap-3">
                <p class="text-xs text-gray-500">{{ item.hour }}</p>
                <div class="flex items-center gap-1">
                  <div
                    class="rounded-md px-1 py-0.5 text-white text-xs"
                    [ngClass]="{
                      'bg-[#FDBA74]': item.type === '상담',
                      'bg-[#46B2A1]':
                        item.type !== '상담' && item.type !== '센터',
                    }"
                    [ngStyle]="
                      item.type === '센터'
                        ? {
                            background:
                              'linear-gradient(to right, #1693C9, #4F5CEF, #6C4FEF)',
                          }
                        : {}
                    "
                  >
                    {{ item.type }}
                  </div>
                  <p class="text-sm font-semibold text-gray-800">
                    {{ item.title }}
                  </p>
                </div>
              </div>
              <div
                [ngClass]="
                  schedule().length - 1 === $index ? 'hidden' : 'block'
                "
                class="w-full border-b border-gray-100"
              ></div>
            }
          </div>
        </div>
      </div>
    </section>
  }
</main>
