/* eslint-disable @nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { CenterService, UserService } from '@malirang/api-client';
import {
  CLIENT_ACCESS_TOKEN_KEY,
  fnGetKeyByValue,
  MemberRole,
} from '@malirang/common';
import {
  AlertService,
  Button,
  Icon,
  LocalStorageService,
  ModalService,
  ToastService,
} from '@malirang/front-share';
import { environment } from 'apps/client/src/env/env';
import { injectNavigationEnd } from 'ngxtension/navigation-end';
import { GuideModal, GuideModalProps } from '../../../modals/guide/guide.modal';
import { CenterStore } from '../../../stores/center.store';
import { UserStore } from '../../../stores/user.store';
@Component({
  selector: 'app-center-guard',
  templateUrl: './center-guard.component.html',
  imports: [CommonModule, Icon, Button, RouterLink, RouterOutlet],
})
export class CenterGuardComponent implements OnInit {
  readonly centerService = inject(CenterService);
  readonly router = inject(Router);
  readonly userStore = inject(UserStore);
  readonly userService = inject(UserService);
  readonly centerStore = inject(CenterStore);
  readonly localStorageService = inject(LocalStorageService);
  readonly toastService = inject(ToastService);
  readonly alertService = inject(AlertService);
  readonly modalService = inject(ModalService);

  navigationEnd$ = injectNavigationEnd();

  user = this.userStore.user;
  centers = this.centerStore.centers;

  path = signal<string>('');
  render = signal(false);

  // 구독, 마이페이지, 고객센터, 비밀번호 변경
  authWhiteList = ['/subscription', '/my-page', '/customer', '/change'];

  /**
   * 허용되는 path 목록
   * path가 목록에 포함되어 있으면 visible을 true로 설정
   */
  whiteList = ['/center/form', '/center/find', ...this.authWhiteList];

  /**
   * 센터에 가입되어 있는지 확인 후 렌더링 여부 결정
   * (whitelist에 포함되어 있으면 렌더링)
   */
  centerGuard = computed(() => {
    if (this.whiteList.find((path) => this.path().startsWith(path))) {
      return true;
    }

    return this.centers() && this.centers()!.length > 0;
  });

  /**
   * 로그인 여부 확인 후 렌더링 여부 결정
   * (whitelist에 포함되어 있으면 렌더링)
   */
  authGuard = computed(() => {
    if (this.authWhiteList.includes(this.path())) {
      return true;
    }

    return this.user();
  });

  ngOnInit() {
    this.handleAuthenticating();

    this.path.set(this.router.url);
    this.navigationEnd$.subscribe({
      next: (event) => {
        this.path.set(event.url);
      },
    });

    this.userStore.user$.subscribe({
      next: (user) => {
        if (user) {
          // 첫 진입 시 가이드 모달 오픈
          const accessed = this.localStorageService.get('firstAccess');
          if (!accessed) {
            this.openGuideModal();
            this.localStorageService.set('firstAccess', true);
          }

          if (user.centerCount) {
            this.centerStore.fetchCenters();
          }
        }
      },
    });
  }

  /**
   * 센터 찾기 가이드 모달 열기
   */
  openGuideModal() {
    this.modalService.create<GuideModalProps>(GuideModal, {
      componentProps: {
        type: 'FIND_CENTER',
      },
    });
  }

  /**
   * 인증 처리
   */
  async handleAuthenticating() {
    const accessToken = this.localStorageService.get(CLIENT_ACCESS_TOKEN_KEY);

    if (accessToken) {
      try {
        const user = await this.userStore.fetchUser();

        if (user) {
          if (
            !user.roles.includes(
              fnGetKeyByValue(MemberRole, MemberRole.TEACHER),
            )
          ) {
            this.userStore.clearUser();
            this.toastService.error('선생님만 이용할 수 있습니다.');
            this.router.navigate(['/login']);
          }

          this.userStore.setUser(user);
        }
      } catch {
        this.userStore.clearUser();
      }
    }

    this.render.set(true);
  }

  login() {
    window.location.href = `${environment.landing.url}/login`;
  }
}
